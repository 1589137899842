@import './colors';

$bg: #343a40;
$border: #474654;
.menu-layout {
  position: absolute;
  margin-top: 3px;
  z-index: 5;
  border: 2px solid $border;
  background-color: $bg;
  &::after {
    content: '';
    top: -8px;
    left: calc(30% - 8px);
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $bg;
  }
  &::before {
    content: '';
    top: -10px;
    left: calc(30% - 10px);
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $border;
  }
}
.menu-btc-eth {
  width: 100%;
  .menu-itm {
    cursor: pointer;
    &:hover {
      background-color: #32373a;
    }
    .icon {
      font-size: 1.2rem;
      color: var(--font-color);
    }
    h3 {
      color: white;
      flex-grow: 1;
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: #{1400px}) {
    .menu-itm {
      .icon {
        font-size: 0.9rem;
      }
      h3 {
        color: white;
        font-size: 0.7rem;
      }
    }
  }
}

.menu-chart {
  position: absolute;
  border: 2px solid $border;
  background-color: $bg;
  width: 100%;
  margin: 0 !important;
  margin-top: -6px !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  .input-dropdown {
    cursor: pointer;
    background: #343440;
    height: 30px;
    border-bottom: 1px solid $border;
    .icon {
      font-size: 18px;
      color: var(--font-color);
    }
    .drop {
      font-size: 18px;
      margin-top: auto;
      margin-bottom: auto;
      color: var(--font-color);
      background: none;
      flex-grow: 1;
      border: none;
      padding-right: 2rem;
      &:focus {
        border: none;
        outline: none;
      }
    }
    &:last-child {
      border-bottom: noneQ;
    }
    &:hover {
      background-color: #32373a;
    }
  }
  @media only screen and (max-width: #{1400px}) {
    .input-dropdown {
      height: 26px;
      .icon {
        font-size: 10px !important;
      }
      .drop {
        font-size: 10px !important;
      }
    }
  }
  &::after {
    content: '';
    display: none;
  }
  &::before {
    content: '';
    display: none;
  }
}
