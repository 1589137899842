@import '../../static/scss/colours';

.academy {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .academy-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;
    justify-content: space-between;
    position: relative;

    .academy-icon {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 1.35rem;
      font-weight: 650;
      color: var(--font-color);
      img {
        width: 80%;
        height: 80%;
      }
    }

    .search-academy {
      width: 43%;
      height: calc(100% - 2rem);
      border: 0.5px solid #e7e7e7;
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: var(--font-color);
        font-weight: 700;
        opacity: 0.25;
        padding-right: 20px;
        justify-content: space-between;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: var(--font-color);
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: var(--font-color);
          font-weight: 700;
          background: transparent;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .w-space {
    width: 100%;
    height: 4%;
    padding: 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 10px 2rem;
        border-radius: 25px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: var(--font-color);
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: var(--font-color);
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .customerss {
    width: 100%;
    height: 88%;
    display: flex;
    transition: all ease 0.4s;
    .leftCont {
      width: 50%;
      height: 100%;
      padding: 3rem 3rem 0 3rem;
      border-right: 0.5px solid #e7e7e7;
      .play-txt {
        font-size: 1.75rem;
        font-weight: 650;
        color: var(--font-color);
        margin-bottom: 1rem;
        height: 3rem;
      }
      .total-playlist {
        width: 100%;
        height: calc(100% - 3rem);
        overflow-y: scroll;
        .each-playlist {
          width: 100%;
          height: 9rem;
          border: 1px solid #e7e7e7;
          border-radius: 10px;
          padding: 1rem;
          display: flex;
          margin-top: 2rem;
          color: var(--font-color);
          gap: 1.5rem;
          cursor: pointer;
          transition: all ease 0.3s;

          .vid-clip {
            height: 100%;
            width: 25%;
          }
          .head-subtxt {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .heaad {
              font-size: 1.25rem;
              font-weight: 650;
            }
          }

          &:hover {
            box-shadow: 0 6px rgb(24 25 29 / 30%);
          }
        }
      }
    }
    .rightCont {
      width: 50%;
      height: 100%;
      padding: 1.25rem 1.25rem 0 1.25rem;
      overflow-y: scroll;
      color: var(--font-color);

      .video-div {
        width: 100%;
        height: 25rem;
        border: 0.5px solid #e7e7e7;
        border-radius: 10px;
      }
      .video-info {
        width: 100%;
        height: 12rem;
        padding: 2.5rem 0;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .info-head {
          font-size: 1.75rem;
          font-weight: 650;
        }
      }
      .owner-card {
        background: rgba(237, 237, 237, 0.37);
        border-radius: 15px;
        width: 100%;
        height: 6.8rem;
        display: flex;
        padding: 1.5rem;
        gap: 1.5rem;

        .owner-txt {
          font-size: 1.5rem;
          font-weight: 700;
        }
      }
      .related-div {
        width: 100%;
        /* height: 5.2rem; */
        display: flex;
        padding: 2.5rem 0 0;
        font-size: 1.3rem;
        font-weight: 650;
      }
      .each-playlist {
        width: 100%;
        height: 9rem;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        padding: 1rem;
        display: flex;
        margin-top: 2rem;
        color: var(--font-color);
        gap: 1.5rem;
        cursor: pointer;
        transition: all ease 0.3s;

        .vid-clip {
          height: 100%;
          width: 25%;
        }
        .head-subtxt {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .heaad {
            font-size: 1.25rem;
            font-weight: 650;
          }
        }
        &:hover {
          box-shadow: 0 6px rgb(24 25 29 / 30%);
        }
      }
    }
  }

  .csoon {
    width: 100%;
    height: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 650;
    color: var(--font-color);
    transition: all ease 0.4s;
  }
}
