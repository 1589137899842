@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@import '_mixin';
@import '_colours';
@import '_hamburgers';

@import 'global';
@import 'layout';
@import 'layoutMobile';
@import 'toast';
@import 'chatComponent';
@import 'selectFileModal';
@import 'modalConfirm';
@import 'modalCountrySelect';
@import 'modalSessionExpired';
@import 'deposit-modal';
@import 'login';
@import 'iceSider';
@import 'portfolioDashboard';
@import 'portfolioAssets';
@import 'vaults-page';
@import 'vault';
@import 'mobileApps';
@import 'desktopAppDetail';
@import 'markets';
@import 'icedindex';
@import 'icedIndexMobile';
@import 'login-modal';
@import 'mobileLoginSignup';
@import 'dashContracts';
@import 'vaultCreateContract';
@import 'issueIcedSteps';
@import 'netWorthPage';
@import 'bondMoreActions';
@import 'iceTrust';
@import 'iceIndices';
@import 'moneyMarkets';
@import 'enterPinUnlock';
@import 'icePayouts';
@import 'iceMechineMobile';
@import 'whatIsAssetsPage';
@import 'whatIsAssetsIo';
@import 'createDefiTrustWrapper';
@import 'transactionInspector';
@import 'options';
@import 'vaultFab';
@import 'actionPanelModal';
@import 'footer';
@import 'bondListTableMobile';
@import 'chatAddCoinComponent';
@import 'fundsComponent';
@import 'leaderboard-drawer';
@import 'trustDetail';
@import 'videoPlayerSidebar';
@import 'iceMarkets';
@import 'marketDetail';
@import 'copyBondModal';
@import 'inboxSidebar';
@import 'getStartedPage';
@import 'settingsChat';
@import 'bondOverviewMobile';
@import 'bondOverviewMobileBond';
@import 'chatsIoComponent.scss';
@import 'chatsIoBots';
@import 'chatsIoLearn';
@import 'chatsIoSupportChat';
@import 'getMobileApp';
@import 'tradeInspector';
@import 'landingPage';

@import './landing/homePage';
@import './landing/inviteModal';
@import './landing/getMobileApp';

:root {
  --font-color: #5F6163;
}

body {
  font-family: Montserrat !important;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  color: var(--font-color);
  #root {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    * {
      font-family: Montserrat;
      outline: none;
      box-sizing: border-box;
    }
  }
}
iframe {
  // display: none;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.8);
}
