@import '../../../static/scss/colours';

.newSidebar {
  position: fixed;
  border-right: 0.5px solid #e5e5e5;
  top: 0;
  z-index: 5;
  left: 0;
  bottom: 0;
  right: calc(100% - 22%);
  background: white;

  .top-text {
    height: 8%;
    width: 100%;
    padding: 10px 10px 10px 1.85rem;
    font-size: 1.5rem;
    font-weight: 610;
    color: var(--font-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #e7e7e7;
  }

  .new-breadcrumb {
    width: 100%;
    height: 4%;
    color: var(--font-color);
    padding-left: 1.85rem;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      margin-bottom: 0;
      margin-top: 0 !important;
    }
  }

  .sending-text {
    width: 100%;
    padding-left: 1.85rem;
    font-size: 0.8rem;
    color: var(--font-color);
    font-weight: 650;
    height: 6%;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #e7e7e7;
  }

  .userAppscard {
    color: var(--font-color);
    width: 100%;
    height: calc(100% - 18%);
    overflow: hidden;
    background-color: white;
    padding: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .userapphead {
      font-weight: 800;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
    }
    .appsearch {
      font-weight: 700;
      font-size: 18px;
      line-height: 16px;
      width: 100%;
      padding: 25px;
      margin: 0px;
      border: 0px;
      border-bottom: 1px solid #e5e5e5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .appsearch::-webkit-input-placeholder {
      opacity: 0.25;
    }
    .appsearch::placeholder {
      opacity: 0.25;
    }
    .apps-card {
      // border: 1px solid red;
      overflow-y: scroll;
      height: calc(100% - 10rem);
      overflow-x: hidden;
      /* margin-top: 2rem; */
      /* border-top: 1px solid #e5e5e5; */
      border-bottom: 1px solid #e5e5e5;
    }

    .newbtns-div {
      width: 100%;
      height: 10rem;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top-btns {
        display: flex;
        width: 100%;
        gap: 2%;
        // border: 1px solid;
        div {
          color: #ffffff;
          font-size: 1.1rem;
          font-weight: 700;
          padding: 0.75rem 2.2rem;
          width: 48%;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .bottom-btn {
        display: flex;
        width: 100%;
        padding: 0.75rem 2.2rem;
        color: #ffffff;
        background: var(--font-color);
        justify-content: center;
        border-radius: 25px;
        font-weight: 700;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.07);
        }
      }
    }
  }

  .newbtns-div {
    width: 100%;
    height: 10rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;

    .top-btns {
      display: flex;
      width: 100%;
      gap: 2%;
      div {
        color: #ffffff;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.75rem 2.2rem;
        width: 48%;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .bottom-btn {
      display: flex;
      width: 100%;
      padding: 0.75rem 2.2rem;
      color: #ffffff;
      background: var(--font-color);
      justify-content: center;
      border-radius: 25px;
      font-weight: 700;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .congrats {
    width: 90%;
  }

  .congrats1 {
    width: 100%;
    font-size: 0.99rem;
    font-weight: 300;
    line-height: 30px;
    padding: 2rem 1.5rem 2rem 1.85rem;
    margin-bottom: 0;
  }

  .pathDataInfo {
    width: 90%;
    height: 100%;
    margin-top: 18%;
  }
  .stepsInfo {
    line-height: 2;
    line-height: 2;
    width: 100%;
    /* border: 1px solid; */
    padding: 0 1.5rem 1rem 1.85rem;
    font-weight: 300;
  }
  .stepDetail {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 1.5% 0 1.5%;
  }
  .arrowActive {
    transform: rotate(180deg);
  }

  .publicName {
    font-size: 1.05rem;
    font-weight: 400;
    width: 75%;
  }
  .arrowIconWrapper {
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .description {
    font-size: 13px;
    line-height: 2;
    width: 100%;
    padding: 2% 1.5% 2% 1.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .forex-sidebar-spacer {
    border-bottom: 0.5px solid #e5e5e5;
    width: 100%;
    margin: 0 auto;
  }

  .pathDataButton {
    border-radius: 30px;
    height: 50px;
    width: 180px;
    padding: 1rem;
    font-size: 13px;
    color: white;
    margin-top: 1rem;
    text-align: center;
  }

  #rotate {
    cursor: pointer;
  }

  .expand {
    margin-top: 4px;
    font-size: 14px;
  }

  .pictureData {
    &.false {
      display: none;
    }
    &.true {
      display: block;
    }
  }

  .stepstocomplete {
    width: 100%;
    height: 44%;
    overflow-y: scroll;
    padding-left: 1.85rem;
    padding-right: 1.5rem;
  }

  .addForexAmountWrapper1 {
    padding-left: 1.85rem;
    overflow-y: scroll;
    height: calc(100% - 10rem - 8%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 1.5rem;
    padding-right: 1.85rem;
  }
}
