@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.shareTradeDetailModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .settingsCard {
    z-index: 1;
    background-color: white;
    height: 75%;
    width: 90%;
    max-width: 550px;
    max-height: 75%;
    margin: auto;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .loadingAnim {
      position: absolute;
      background: #ffffff;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      .loadingText {
        position: absolute;
        left: 0;
        bottom: 10%;
        right: 0;
        margin: auto;
        text-align: center;
        font-size: 24px;
      }
    }
    .inCard {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      color: var(--font-color);
      .coinDetail {
        display: flex;
        flex-direction: column;
        align-items: center;
        .coinIcon {
          margin-top: 30px;
          height: 80px;
          width: 80px;
        }
        .coinName {
          font-size: 26px;
          display: flex;
          align-items: center;
          font-weight: bolder;
        }
        .symbol {
          margin-top: 10px;
          font-size: 16px;
          display: flex;
          align-items: center;
          line-height: 1;
          img {
            height: 18px;
            width: 18px;
            margin-right: 3px;
            object-fit: contain;
          }
          span {
            @include btn-animate;
            img {
              border-radius: 9px;
              object-fit: cover;
            }
            &:nth-of-type(2) {
              margin: 0 12px;
              padding: 0 12px;
              border-left: 1px solid $border-color;
              border-right: 1px solid $border-color;
            }
          }
        }
        .assetCoin {
          margin-top: 15px;
          height: 30px;
          display: flex;
          padding: 6px;
          border: 1px solid $border-color;
          border-radius: 5px;
          align-items: center;
          padding: 4px 10px;
          img {
            height: 16px;
            width: 16px;
            margin-right: 6px;
          }
          span {
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
      .topBar {
        flex: 0 0 100px;
        background: $app-color;
        width: 100%;
      }
      .logoWrap {
        height: 100px;
        width: 100px;
        background: white;
        margin-top: -50px;
        border: 1px solid $border-color;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 70px;
          width: 70px;
          object-fit: contain;
        }
      }
      .title {
        font-size: 26px;
        font-weight: 600;
      }
      .subTitle {
        font-size: 17px;
        line-height: 1;
      }
      .listItems {
        width: 100%;
        flex: 1;
        padding: 0 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .listItem {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          font-weight: 600;
          .label,
          .value {
            display: flex;
            align-items: center;
            img {
              height: 20px;
              width: auto;
              margin-right: 8px;
            }
          }
        }
      }
      .successText {
        margin: auto;
        font-size: 25px;
        font-weight: 600;
      }
    }
    .profile {
      padding: 40px 40px 0px 40px;
      .dp {
        height: 100px;
        width: 100px;
        border-radius: 50px;
      }
      .name {
        font-size: 22px;
        font-weight: 600;
        margin: 5px 0;
      }
      .email {
        line-height: 1;
        font-size: 16px;
        font-weight: 400;
      }
      .title {
        margin-top: 30px;
        font-size: 22px;
      }
      .bonds {
        margin: auto 0;
        width: 100%;
        justify-content: space-around;
        padding: 20px;
        .inpRow {
          display: flex;
          justify-content: space-between;
          font-size: 22px;
          padding: 5px 0;
          border-bottom: 1px solid $border-color;
          font-weight: 600;
          input {
            font-size: 24px;
            border: none;
            background: none;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
      .search {
        width: 100%;
        height: 50px;
        border: 1px solid $border-color;
        border-radius: 10px;
        margin: 20px 0;
        padding: 0 20px;
      }
      .appList {
        height: 0;
        width: 100%;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: auto;
        .appCard {
          flex: 0 0 30%;
          height: 140px;
          border: 1px solid $border-color;
          margin-bottom: 20px;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            height: 55px;
            width: 55px;
            margin-bottom: 10px;
          }
          span {
            font-size: 15px;
            font-weight: 600;
            text-align: center;
          }
          .value {
            font-size: 12px;
            font-weight: 500;
          }
          &:hover,
          &.true {
            background-color: whitesmoke;
          }
        }
      }
    }
    .footerBtns {
      flex: 0 0 80px;
      display: flex;
      .btnAssets,
      .btnQuickBuy {
        flex: 0 0 50%;
        border: 1px solid $border-color;
        color: $primary-color;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
      }
      .btnQuickBuy {
        @include btn-animate();
      }
    }
  }
}
