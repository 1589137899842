@import './colours';

.mobileLogin {
  z-index: 3;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .login-enter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 35px;
    position: relative;
    .main-text {
      font-size: 50px;
      font-weight: 600;
      color: $primary-color;
    }
    .sub-text {
      font-size: 18px;
      color: var(--font-color);
      margin-bottom: 20px;
    }
    .stepContent {
      display: flex;
      flex-direction: column;
      .group {
        position: relative;
        margin-bottom: 15px;
        height: 60px;
        background-color: white;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #22397410;
        img {
          margin: 0 10px;
          height: 20px;
          width: 20px;
        }
        input {
          height: 100%;
          width: 0;
          flex-grow: 1;
          background: none;
          border: none;
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            background: none !important;
            transition: background-color 5000s ease-in-out 0s;
          }
        }
        .eye {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 20px;
          margin: auto;
          height: 15px;
          width: 15px;
        }
        &:focus-within {
          box-shadow: 0px 0px 30px 0 rgba(0, 0, 0, 0.1);
          border-color: transparent;
        }
      }
      .info {
        font-size: 14px;
        margin-bottom: 30px;
      }
      .conditions {
        border-radius: 0px;
        padding: 0;
        h3 {
          font-size: 20px;
          color: var(--font-color);
          opacity: 0.41;
        }
        .item {
          padding: 6px 0;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          svg {
            height: 12px;
            margin: auto 0;
          }
        }
      }
      .otp-group {
        .text {
          color: $primary-color;
          font-size: 14px;
        }
        .otp-input-wrapper {
          margin: 10px 0;
          height: 55px;
          display: flex;
          justify-content: space-between;
          > div {
            flex: 0 0 15%;
            .otp-input {
              width: 100% !important;
              height: 100%;
              font-size: 20px;
            }
          }
        }

        .otp-input-wrapper-onhold {
          width: 100%;
          justify-content: space-between;
          > div {
            width: 20%;
            height: 80px;
            .otp-input {
              width: 100% !important;
              height: 100%;
              border: none;
              background: #ffffff;
              box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;
              font-size: 28px;
            }
          }
        }
      }
      .btn-next {
        cursor: pointer;
        margin-top: 10px;
        height: 50px;
        width: 65%;
        border-radius: 0;
        color: white;
        background-color: $primary-color;
        &.final {
          width: 100%;
        }
      }
      .signUpLottie {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .lottie {
          margin: 20% 0;
        }
        h6 {
          width: 70%;
          text-align: center;
        }
      }
    }
  }
  .bottomBtn {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-color;
    font-size: 18px;
    font-weight: 600;
    color: white;
  }
}
