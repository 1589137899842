@import '../../static/scss/colours';

.InvestDetail-hodlings {
  height: calc(100% - 7.5rem);
  width: 100%;
  display: flex;
  align-items: self-start;
  margin-top: 3rem;
  justify-content: space-between;
  .investmentcardSection {
    width: 32%;

    .inv-detail {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      color: var(--font-color);
    }
    .innerInvestmentCard {
      width: 100%;
      height: calc(100vh - 16.7rem);
      border-radius: 10px;
      border: 0.5px solid #e5e5e5;
      display: flex;
      flex-wrap: wrap;
      overflow: scroll;
      .innerItemCard {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 123px;
        padding-left: 18px;
        padding-right: 18px;
        border-right: 0.5px solid #e5e5e5;
        border-bottom: 0.5px solid #e5e5e5;
        h2 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          color: var(--font-color);
        }
        h1 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 27px;
          display: flex;
          align-items: center;

          color: var(--font-color);
        }
      }
    }
  }
}

.holdingPage {
  width: 100%;
  height: 88%;
  .contianer-holding {
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    .sectionskeletonHoding {
      height: 1rem;
      width: 80%;
      margin-bottom: 1rem;
    }
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: var(--font-color);
    }

    .holdingcard {
      align-items: center;
      width: 100%;
      display: grid;
      grid-auto-columns: 231px;
      grid-column-gap: 15px;
      grid-auto-flow: column;
      padding: 2px 2px;
      list-style: none;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
    }
    .inner {
      width: 231px;
      height: 145px;
      cursor: pointer;
      padding: 21px;
      background: #ffffff;
      border: 0.5px solid #ddd;
      border-radius: 10px;
    }
    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      display: flex;
      align-items: center;

      color: var(--font-color);
      margin-bottom: 0;
    }
    h3 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: var(--font-color);
    }
    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: var(--font-color);
      margin-top: 5px;

      opacity: 0.5;
    }
    .img-holding {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        margin-right: 10px;
        height: 22px;
      }
      h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;

        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0;
        color: var(--font-color);
      }
    }
  }
}
