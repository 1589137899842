@import '../../../../static/scss/colours';

.sectionskeleton {
  height: 3rem;
  width: 70%;
}
.Tablecard {
  display: flex;
  align-items: self-start;
  gap: 3.4%;
  margin-bottom: 2.5rem;
  .innerTablecard {
    width: 294px;
    height: 152px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 20px;
    padding-left: 2rem;
    &:hover {
      transform: translateY(-5px);
      // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    img {
      height: 38.23px;
    }
    .fundsNamesub {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-top: 1.2rem;

      color: var(--font-color);
    }
  }
}
.TableSection {
  .headerFunds {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;

    padding-left: 1rem;
  }

  .headerFundsEA {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 1.5fr 1.5fr 1fr;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;

    padding-left: 1rem;
  }
  .fund-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 50px;
    /* or 455% */

    color: var(--font-color);
  }

  .fundBody {
    .img-table-frist {
      display: flex;
      align-items: center;
      margin-top: 2px;
    }
    .sectionLeftBody {
      display: flex;
      align-items: center;
    }
    .InnerFundsBody {
      padding-top: 3rem;
      padding-bottom: 3rem;
      border-bottom: 0.5px solid #e7e7e7;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }

    .InnerFundsBodyEa {
      padding-top: 3rem;
      padding-bottom: 3rem;
      border-bottom: 0.5px solid #e7e7e7;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .InnerFundsBody {
      display: grid;
      grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
    }

    .InnerFundsBodyEa {
      display: grid;
      grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 1.5fr 1.5fr 1fr;
      padding-left: 1rem;
    }
    .text-funds {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      display: flex;
      align-items: center;
      line-height: 18px;

      color: var(--font-color);
    }
    .labelcover {
      display: flex;
      align-items: center;
    }
    .buylabel {
      width: 109px;
      height: 31px;
      left: 1355px;
      top: 443px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;

      background: #9fd6df;
      border-radius: 25px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .logo-fund-inner {
      height: 14px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .logo-fund {
      height: 37px;
      margin-right: 10px;
    }
    .text-funds-inner {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;

      color: var(--font-color);
    }
  }
}
