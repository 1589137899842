@import '../../../static/scss/colours';

// ViewAddress Step One

.submitButton {
  width: 178px;
  border: solid 1px #e5e5e5;
  border-radius: 15px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrContainer {
  // width: 220,
  // height: 220,
  margin: 60px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addressStyle {
  font-weight: bold;
  font-size: 15px;
  padding: 30px 23px;
  color: var(--font-color);
}
.copyButton {
  width: 148px;
  border: solid 1px #e5e5e5;
  border-radius: 15px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addressWrapper {
  border: solid 0.5px #ebebeb;
  border-radius: 20px;
  height: 72px;
  text-align: center;
}

.doubleButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}
.fullButtonWhite {
  border: solid 1px #e5e5e5;
  border-radius: 15px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.singleButton {
  margin-top: 15px;
  // marginBottom: 15,
}
.fullButton {
  border: solid 1px #e5e5e5;
  border-radius: 15px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5f6163;
  margin-bottom: 20px;
}

// View Address Step Two (Submit Hash)

.addressInput1 {
  height: 63px;
  border: none;
  margin: 40px 0px;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding-left: 20px;
}

.addressInput {
  height: 63px;
  border: none;
  // margin: 40px 0px;
  // border-bottom: 1px solid #e7e7e7;
  border-radius: 20px;
  padding-left: 20px;
}

// View Address Step Three (Hash Success)

.cardlabel {
  color: var(--font-color);
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 15px;
  padding-top: 60px;
  padding-left: 2;
}
.cardStyle {
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  padding: 0px 20px;
}
.cardImage {
  height: 24px;
  width: 24px;
}
.cardText {
  font-size: 15px;
  color: var(--font-color);
  font-weight: 700;
  padding-left: 9px;
}

// Withdraw Crypto CSS

.balanceText {
  font-size: 35px;
  font-weight: bold;
  color: var(--font-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.balanceTextCoin {
  font-size: 20px;
  font-weight: bold;
}
.usdBalance {
  font-weight: bold;
  text-align: center;
  color: var(--font-color);
  font-size: 16px;
}

.inputForm {
  margin: 50px 0px;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.input {
  flex-grow: 1;
  width: 0px;
  color: var(--font-color);
  font-size: 25px;
  font-weight: bold;
  padding: '0px 10px';
  border: none;
}
.focusedInput {
  color: #1a6bb4;
}
.inputButton {
  border: 1px solid #ededed;
  justify-content: center;
  padding: 2px 8px;
  margin-left: 5px;
}
.inputButtonText {
  color: var(--font-color);
  font-size: 10px;
}
.cryptoName {
  // marginLeft: 10,
  color: var(--font-color);
  font-size: 18px;
  font-weight: bold;
}
.focusedText {
  color: var(--font-color);
}
.divider {
  background-color: #5f6163;
  height: 2px;
  margin: 5px 0px;
}

.cardlabel {
  color: var(--font-color);
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 15px;
  padding-top: 40px;
  padding-left: 2px;
}
.cardStyle {
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
}
.cardStyle1 {
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}

.cardImage {
  height: 24px;
  width: 24px;
}
.cardText {
  font-size: 15px;
  color: var(--font-color);
  font-weight: 700;
  padding-left: 9px;
}
.cardTextSub {
  font-size: 8px;
  color: var(--font-color);
  font-weight: 300;
  padding-top: 2px;
  padding-left: 8px;
}
.coinImageStyle {
  width: 13px;
  height: 13px;
}

//In Card CSS
.inCardWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e5e5e5;
}
.inCardWrapper1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0px;
  border-color: #e5e5e5;
}
.inCardCurrency {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 0px 20px;
  height: 32px;
}

.debitAmountStyle {
  font-size: 20px;
  color: var(--font-color);
  font-weight: 700;
}
.coinImageStyle {
  width: 13px;
  height: 13px;
}
.coinSymbolStyle {
  font-size: 11px;
  font-weight: 800;
  color: var(--font-color);
  padding-left: 4px;
  padding-right: 11px;
}

.arrowStyle {
  width: 6px;
  height: 6px;
  padding-left: 11px;
}
.labelStyle {
  font-size: 12px;
  line-height: 25px;
}
.feeLabelStyle {
  padding-right: 7px;
}
.descText {
  font-size: 12px;
  margin-top: 15px;
  color: var(--font-color);
  line-height: 22px;
}

.confirmHeader {
  font-weight: 700;
  color: var(--font-color);
  margin-top: 45px;
  margin-bottom: 28px;
  font-size: 20px;
}
.confirmation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // margin-top: 15px;
}
.confirmationText {
  margin-left: 12px;
  font-size: 11px;
  color: var(--font-color);
  line-height: 25px;
  margin-top: -4px;
}

.email-code-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  .email-code {
    width: 100%;
    input {
      height: 60px;
      width: 90% !important;
      border: 0.5px solid #e5e5e5;
      border-radius: 5px;
    }
  }
}

// Add Forex Flow

.midSectionStyle {
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  margin: 0px 22px;
}

.currencyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 74px;
  padding-left: 20px;
  // border-color: ThemeData.BORDER_COLOR,
  border-top: 1px solid #e7e7e7;
}
.currencyIcon {
  width: 23px;
  height: 23px;
}
.currencyName {
  font-weight: bold;
  font-size: 15px;
  padding-left: 5px;
}
.row {
  flex-direction: row;
  align-items: center;
  margin: 15px 0px;
}
.coinContainer {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.coinImage {
  width: 25px;
  height: 25px;
  border-radius: 13px;
  margin-right: 5px solid #e7e7e7;
}
.coinName {
  font-weight: bold;
}

// Withdraw Forex

.accCardStyle {
  border-top: 1px solid #e5e5e5;
  height: 180px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.accCardStyle1 {
  height: 180px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.instituteWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.instituteName {
  font-weight: 700;
  color: var(--font-color);
  font-size: 20px;
  padding-left: 8px;
}
.accountNameLabel {
  color: var(--font-color);
  font-size: 9px;
  padding-bottom: 8px;
}
.accountNameStyle {
  font-weight: 700;
  color: var(--font-color);
  font-size: 14px;
}
.paymentMethodStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.paymentMetodLable {
  font-weight: 700;
  color: var(--font-color);
  font-size: 17px;
  padding-left: 4px;
}

//Buy Bond

.coverDiv {
  height: 10vh;
}
.imgCointainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  width: 97px;
  height: 97px;
  border-radius: 10px;
  background-color: white;
  border: 0.5px solid #e7e7e7;
}

.imgWrapper {
  display: flex;
  justify-content: center;
}
.dataContainer {
  padding: 0px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55vh;
}

.vaultsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // justifyContent: 'center',
  // alignItems: 'center',
}
.vault {
  padding: 14px 0px;
  width: 103px;
  border-radius: 10px;
  background-color: white;
  border: 0.5px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.vaultName {
  font-weight: 700;
  font-size: 12px;
  padding-top: 15px;
  color: var(--font-color);
}
.vaultValue {
  font-size: 9px;
  padding-top: 5px;
  color: var(--font-color);
}
.inputWrapper2 {
  border: 0.5px solid #e5e5e5;
  height: 20px;
  display: flex;
  justify-content: center;
  padding: 21px;
  border-radius: 10px;
  margin: 20px 0px;
  width: 100%;
}

// cardlabel: {
//   fontFamily: ThemeData.FONT_SEMI_BOLD,
//   color: 'var(--font-color)',
//   fontSize: 13,
//   fontWeight: '600',
//   paddingBottom: 15,
//   paddingTop: 40,
//   paddingLeft: 2,
// },
// cardStyle: {
//   borderWidth: 0.5,
//   borderColor: '#E5E5E5',
//   borderRadius: 15,
//   height: 74,
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   paddingHorizontal: 20,
// },
// cardStyle1: {
//   borderWidth: 0.5,
//   borderColor: '#E5E5E5',
//   borderRadius: 15,
//   // height: 74,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   // alignItems: 'center',
//   paddingLeft: 25,
//   paddingRight: 25,
// },
// cardImage: {
//   height: 24,
//   width: 24,
// },
// cardText: {
//   fontFamily: ThemeData.FONT_BOLD,
//   fontSize: 15,
//   color: var(--font-color),
//   fontWeight: '700',
//   paddingLeft: 9,
// },
