@import '../../static/scss/colours';

.eaConnectContainer {
  width: 100%;
  height: 100%;
  //   border: 1px solid red;

  & > .eaConnectHead {
    background: #e5e5e540;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 3rem;

    & > div {
      color: var(--font-color);
      font-size: 0.9rem;
      padding: 0.75rem 0;
      font-weight: 600;
      //   border: 1px solid blue;
    }

    .name {
      width: 13%;
    }

    .acctNum {
      width: 17.5%;
    }

    .serverName {
      width: 13%;
    }

    .masterPass {
      width: 13%;
    }

    .statuss {
      width: 13%;
    }

    .dateofSub {
      width: 19.5%;
    }

    .actionBtn {
      width: 11%;
      display: flex;
      // justify-content: center;
      align-items: center;
    }
  }

  & > .eaConnectContent {
    overflow-y: scroll;
    & > .eachItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      padding: 2.5rem 3rem;
      border-bottom: 0.5px solid #e7e7e7;

      & > div {
        color: var(--font-color);
        font-size: 1rem;
        font-weight: 500;
        // border: 1px solid red;
      }
      .name {
        width: 13%;
      }

      .acctNum {
        width: 17.5%;
      }

      .serverName {
        width: 13%;
      }

      .masterPass {
        width: 13%;

        & > input {
          width: 100%;
          border: none;
          outline: none;
          pointer-events: none;
        }
      }

      .statuss {
        width: 13%;
      }

      .dateofSub {
        width: 19.5%;
      }

      .actionBtn {
        width: 11%;
      }

      // & > :last-child {
      //   background: #9fd6df;
      //   border-radius: 25px;
      //   padding: 0.5rem 1.5rem;
      //   color: white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   cursor: pointer;
      // }
    }
  }
}
