@import '../../static/scss/colours';

.s-selection-master {
  // display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
}

.s-selection-header {
  border-bottom: 1px solid #e7e7e7;
  height: 12vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.s-selection-header div {
  width: 22%;
}

.s-selection-header img {
  // border: 1px solid red;
  width: 7vw;
}

.money-logo {
  margin-left: 50px;
}

.partition-div {
  display: flex;
  width: 100%;
  height: 100%;
}

.left-div {
  border-right: 1px solid #e7e7e7;
  width: 50%;
  height: 100%;
  position: relative;
}

.select-asset {
  // border: 1px solid red;
  position: absolute;
  bottom: 60px;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.flex-div {
  width: 100%;
  display: flex;
  // border: 1px solid blue;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10%;
}

.flex-div div {
  width: 100%;
}

.flex-div p {
  font-size: calc(12px + 2vw);
  font-weight: 800;
}

.text-size {
  font-size: calc(5px + 1vw);
}

.asset-select-input {
  margin-top: 25px;
  border-radius: 16px;
  border: 0.5px solid #e5e5e5;
  height: 67px;
  width: 67%;
  box-sizing: border-box;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 800;
  color: var(--font-color);
  opacity: 0.25;
}

.right-div {
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.s-selection-footer {
  border-top: 1px solid #e7e7e7;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  width: 100%;
  display: flex;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.select-btn {
  width: 33.33%;
  height: 100%;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.assets-vault {
  border-bottom: 0.5px solid #e5e5e5;
  height: 15.3%;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5%;
  justify-content: space-between;
}

.days-txt {
  font-size: calc(5px + 1vw);
  font-weight: 700;
  white-space: nowrap;
}

.amount-data {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: calc(5px + 1vw);
  font-weight: 700;
}

.padding-div {
  padding: 5%;
  height: 100%;
}

.amount-data > div > p:first-child {
  margin-top: 3%;
}

.bonds-txt {
  margin-top: 13%;
}

.per-input {
  margin-top: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
}

.per-input div {
  width: 25%;
  border: 0.5px solid #e5e5e5;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.inputbox {
  width: 25%;
  border: 0.5px solid #e5e5e5;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: calc(5px + 1vw);
  font-weight: 700;
}

.purchase-footer {
  border-top: 1px solid #e7e7e7;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  text-align: center;
  bottom: 0;
  width: 100%;
  position: absolute;
  align-items: center;
}

.skl-txt1 {
  margin-left: 10%;
}

.img-txt {
  display: flex;
  align-items: center;
}

.s-selection-footer.active {
  background: orange;
}
