@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.bplannerPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 3rem;

  .headingDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;

    ::before {
      content: '';
      position: absolute;
      left: -50%;
      // bottom: 25%;
      height: 7px;
      width: 40%;
      background-color: #fca203;
    }
    ::after {
      content: '';
      position: absolute;
      right: -50%;
      // bottom: 25%;
      height: 7px;
      width: 40%;
      background-color: #fca203;
    }

    .feature {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      font-weight: 700;
      color: var(--font-color);
    }
  }

  .comingText {
    font-size: 4rem;
    font-weight: 700;
    color: var(--font-color);
  }

  .workingText {
    font-size: 2rem;
    font-weight: 600;
    color: var(--font-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bottomLine {
    height: 5px;
    bottom: 25%;
    width: 20%;
    background-color: #fca203;
    margin-top: 2rem;
  }
}
