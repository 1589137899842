@import '../../static/scss/colours';

.list-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  .userdata {
    display: flex;
    justify-content: space-between;
    height: 30%;
    padding: 4rem 5rem;
    .data-loader {
      opacity: 0.5;
    }
    .left-data {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      h6 {
        font-weight: 700;
      }
    }
    .refer {
      h6 {
        cursor: pointer;
        text-decoration: underline;
        font-size: 15px;
        margin-top: 5px;
        &:hover {
          transition: all 0.4s ease-in-out;
          transform: scale(1.1);
        }
      }
    }
    .right-data {
      display: flex;
      flex-direction: column;
      .value {
        font-weight: 700;
        font-size: 30px;
        text-align: right;
      }
      .value-label {
        font-size: 15px;
        text-align: right;
        margin-top: 5px;
      }
    }
    h6 {
      color: white;
      font-size: 30px;
    }
  }

  .vaultss-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem 1rem 3rem;

    .vaultss-icon {
      width: 10%;
      height: 100%;
      // border: 1px solid red;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .search-vaultss {
      width: 40%;
      height: 100%;
      border: 0.5px solid #ededed;
      // background: rgba(237, 237, 237, 0.37);
      background: white;
      border-radius: 25px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: relative;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: var(--font-color);
        font-weight: 700;
        opacity: 0.25;
        /* grid-gap: 1rem; */
        /* gap: 1rem; */
        justify-content: space-between;
        padding-right: 15px;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: var(--font-color);
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          width: -webkit-fill-available;
          border: none;
          font-size: 1rem;
          color: var(--font-color);
          font-weight: 700;
          background: transparent;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .trackerBtn {
      height: 100%;
      padding: 0 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.9;
      border-radius: 35px;
      color: white;
      font-weight: 500;
      transition: all ease-in 0.2s;

      &:hover {
        scale: 1.05;
      }
    }

    .right-top {
      width: 30%;
      height: 100%;
      display: flex;
      .divv {
        // width: 50%;
        height: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 0.9rem;
          color: var(--font-color);
          font-weight: 600;
        }
      }
    }
  }

  .w-space {
    width: 100%;
    height: 4%;
    padding: 0 4rem 0 3rem;
    border-bottom: 0.5px solid #e7e7e7;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .optionss-divider {
        display: flex;
        width: max-content;
        height: 100%;
        gap: 1.5rem;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
        .each-option-selected {
          background: rgba(229, 229, 229, 0.25);
          font-weight: 600;
          padding: 10px 1rem;
          border-radius: 5px;
        }
        .each-option {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 2rem;
          border-radius: 25px;
          white-space: nowrap;

          p {
            margin-bottom: 0;
            font-size: 0.9rem;
          }
          div {
            display: flex;
            gap: 5px;
            transition: all ease 0.3s;
            cursor: pointer;
            &:hover {
              scale: 1.1;
            }
          }
        }
      }
      .last-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .toggle {
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;

          .togglecomp {
            // border: 1px solid red;
            width: 10rem;
            display: flex;
            align-items: center;
            gap: 10px;
            p {
              margin-bottom: 0;
            }
            .toggle-div {
              margin-left: 0;
              margin-top: -16px;
            }
          }
        }
      }
    }
  }

  .cryptocard {
    margin: auto;
    // margin-top: -6rem;
    background: white;
    border-radius: 20px;
    // position: fixed;
    top: 17rem;
    height: 90%;
    overflow: scroll;
    border: 0.5px solid #e5e5e5;
    .cardnav {
      background: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      z-index: 1;
      overflow: hidden;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      height: 80px;
      position: fixed;
      width: inherit;
      .searchbox {
        padding: 10px;
        width: 60%;
        height: inherit;
        .searching {
          height: 15px;
          margin-top: 22px;
          margin-left: 20px;
          cursor: pointer;
        }
        .searchfield {
          font-size: 16px;
          font-weight: 700;
          color: var(--font-color);
          width: 95%;
        }
        .searchfield::-webkit-input-placeholder {
          opacity: 0.25;
        }
        .searchfield::placeholder {
          opacity: 0.25;
        }
      }

      .icons {
        .toggle {
          height: inherit;
          width: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-right: 1px solid #e7e7e7;
          border-left: 1px solid #e7e7e7;
          // margin-top: 5px;
          justify-content: center;
        }
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .iconbox {
          border-right: 1px solid #e7e7e7;
          height: inherit;
          width: 80px;
          display: flex;
          justify-content: center;
          cursor: pointer;
        }
        .iconbox1 {
          border-right: none;
          height: inherit;
          width: 80px;
          display: flex;
          justify-content: center;
          cursor: pointer;
        }

        .icondiv {
          .iconsimg {
            height: 30px;
            margin-top: 25px;
            opacity: 0.5;
          }
        }

        .icondiv :hover {
          opacity: 1;
          transform: scale(1.3);
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .searchfield {
      border: none;
      outline: none;
      padding: 10px;
      width: 500px;
    }
    .cryptoData {
      padding: 25px;
      padding-bottom: 80px;
      margin-top: 50px;

      .main-card {
        border-bottom: 0.5px solid #e5e5e5;
        padding: 2rem 0;
        padding-left: 3rem;
        padding-right: 4rem;
        transition: 0.2s ease-in-out;
      }
      .bankIndividual {
        margin-top: 25px;
        padding: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 15px;
      }
      .individual {
        margin-left: 4px;
        // margin-top: 25px;
        // padding: 20px;
        // border: 1px solid #e7e7e7;
        // border-radius: 15px;
        display: flex;
        justify-content: space-between;
        .minidiv {
          display: flex;
          justify-content: space-between;
          width: 65%;
          // padding-right: 10vw;
        }
        .buttondiv {
          display: flex;
          justify-content: end;
          width: 20%;
          color: white;

          & > :nth-child(1) {
            background: #9a4c97;
            border: 0.5px solid #9a4c97;
            border-radius: 5px;
            color: white;
          }

          & > :nth-child(2) {
            background: #9fd6df;
            border: 0.5px solid #9fd6df;
            border-radius: 5px;
            color: white;
          }
        }
        .coinimage {
          width: 50px;
          height: 50px;
          left: 427.0703125px;
          top: 575.3186645507812px;
          border-radius: 0px;
        }
        .cardheading {
          font-weight: 700;
          font-size: 17px;
          line-height: 21px;
        }
        .cardheading1 {
          font-weight: 700;
          font-size: 17px;
          line-height: 21px;
        }
        .cardpara {
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
        }
        .actionbtn {
          color: var(--font-color);
          padding: 25px;
          background-color: white;
          font-weight: 700;
          font-size: 11px;
          line-height: 13px;
          border: 1px solid #e7e7e7;
          border-radius: 6px;
          margin-left: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 50px;
          margin-top: 15px;
        }
        .actionbtn:hover {
          color: white;
          // background-color: black;
          scale: 1.05;
        }
      }
      .main-card:hover {
        // transform: scale(1.006);
        background: #f3f3f3;
      }
    }
  }

  .investment-container {
    width: 100%;
    height: calc(100% - 12%);
    padding-left: 3rem;
    color: var(--font-color);

    .header-div {
      width: 100%;
      height: 11rem;
      padding: 3rem 0;

      .value {
        font-size: 1.95rem;
        font-weight: 700;
      }

      .header-txt {
        font-size: 1.3rem;
        font-weight: 400;
      }
    }

    .tabs-section {
      width: 100%;
      height: 4.5rem;
      display: flex;
      border-bottom: 0.5px solid #e7e7e7;
      gap: calc(8% / 3);
      div {
        width: 23%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0;
        cursor: pointer;
        p {
          margin-bottom: 0;
          font-size: 0.9rem;
          font-weight: 700;
          opacity: 0.5;
          transition: all ease 0.3s;
        }
        &:hover {
          p {
            transform: scale(1.1);
          }
        }
      }
    }

    .investment-data {
      width: 100%;
      height: calc(100% - 0px);
      overflow-y: scroll;
      display: flex;
      padding-right: 3rem;
      gap: 4%;
      flex-wrap: wrap;
      padding-bottom: 2rem;

      .investment-card {
        width: 48%;
        padding: 2.5rem;
        display: flex;
        flex-direction: column;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 3rem;
        height: 20rem;
        justify-content: space-between;
        cursor: pointer;

        .top-data {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .coin-data {
            display: flex;
            flex-direction: column;
            gap: 5px;
            img {
              width: 50px;
              height: 50px;
            }
            div {
              font-size: 1.25rem;
              font-weight: 650;
            }
          }

          .coin-value {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            gap: 5px;
          }
        }

        .bottom-data {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .total-value {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
        }

        .text1 {
          font-size: 0.8rem;
          font-weight: 400;
        }

        .text2 {
          font-size: 1.25rem;
          font-weight: 650;
        }

        &:hover {
          box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
            0 2px 4px 0 rgba(0, 0, 0, 0.1),
            inset 0 0 0 1px hsla(0, 0%, 100%, 0.05);
        }
      }
    }
  }
}
