@import '../../../static/scss/colours';

.tabSelectedInmarket {
  border: 0.5px solid #e5e5e5;
  height: 56px;
  display: flex;
  align-items: center;
  color: var(--font-color);

  border-top: 0px;

  position: relative;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: center;

    color: var(--font-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: max-content;
    border-right: 0.5px solid #e5e5e5;
    padding-left: 3.2rem;
    padding-right: 3.5rem;
    transition: all 400ms ease;
    &:hover {
      transition: all 400ms ease;
      font-size: 18px;
    }
  }
}

.describtionmarket {
  padding: 0 3rem;
  padding-bottom: 2rem;
  padding-top: 2rem;

  background: #f9f9f9;
  .img-sec {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    img {
      height: 25px;
      margin-right: 13px;
    }
    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: var(--font-color);

      margin-bottom: 0;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 34px;
    /* or 185% */
    margin-bottom: 0;
    display: flex;
    align-items: center;

    color: var(--font-color);
  }
}
