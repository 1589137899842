@import '../../static/scss/colours';

.createDefiTrustWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  .overlayTrust {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .createTrustModal {
    z-index: 2;
    margin: auto;
    width: 95% !important;
    max-width: 900px;
    display: flex;
    .login-enter {
      background: #fff;
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      max-width: 50%;
      padding: 60px 60px;
      border-radius: 0;
      border: 1px solid $vault-border-color;
      .login-text {
        color: $primary-color;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        line-height: 1;
      }
      .gx-account-text {
        font-size: 12px;
        color: $primary-color;
      }
      .login-form {
        .group {
          position: relative;
          margin: 0;
          margin-top: 50px;
          input,
          button {
            width: $width;
          }
          input[type='password'],
          input[type='text'] {
            background: none;
            color: $primary-color;
            font-size: 18px;
            padding: 10px 10px 10px 0;
            display: block;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $primary-color;
            &:focus {
              outline: none;
            }
            &:focus ~ label,
            &:valid ~ label {
              top: -14px;
              font-size: 12px;
              color: var(--font-color);
              font-weight: 700;
            }
            &:focus ~ .bar:before {
              width: $width;
            }
          }

          input[type='password'] {
            letter-spacing: 0.3em;
          }
          label {
            color: var(--font-color);
            font-size: 16px;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: 0;
            top: 10px;
            transition: $trans-time ease all;
            text-transform: uppercase;
          }
          .eye {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            color: $primary-color;
          }
        }
      }
      &.decentralised {
        p {
          margin-top: auto;
          font-size: 14px;
        }
        .btn-primary-col {
          margin-top: auto;
        }
      }
    }

    .btn-primary-col {
      color: #fff;
      background: $primary-color;
      font-size: 1.2rem;
      height: 52px;
      margin-bottom: 0.5rem;
      border-radius: 4px;
      &:hover {
        color: #fff;
        background-color: #38393a;
        border-color: #38393a;
      }
      &:disabled {
        opacity: 0.7;
      }
    }
  }
}
