@import '../../../../static/scss/colours';

.fx-content {
  width: 100%;
  height: 88%;
  display: flex;
  position: relative;

  .left-fund-container {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 2.5rem 2.5rem 0;

    .cards-funds {
      // display: flex;
      // flex-wrap: wrap;
      // align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      gap: 1%;
      // height: 100%;
      // overflow: scroll;
      .ItemCards {
        width: 32.63%;
        cursor: pointer;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 15px;
        height: 320px;
        margin-bottom: 1rem;
        .sectionskeletoncard1 {
          height: 5rem;
          width: 70%;
        }
        .sectionskeletoncard {
          height: 2rem;
          width: 50%;
        }

        &:hover {
          transform: translateY(-5px);
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
        .SectionBottomFunds {
          padding-left: 5%;
          padding-right: 5%;
        }
        .ImageSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          top: -29px;
          padding-left: 5%;
          padding-right: 5%;
          width: 100%;
          img {
            height: 38.23px;
          }
          .Rightbox {
            width: 82px;

            height: 30px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--font-color);
            justify-content: center;
            background: #ffffff;
            border: 0.5px solid #e5e5e5;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
            }
          }
          .leftbox {
            width: 62px;
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border: 0.5px solid #e5e5e5;
            border-radius: 5px;
          }
        }
        .bottomcard {
          position: relative;
        }
        .topCard {
          width: 100%;
          height: 88px;
          border-radius: 15px 15px 0px 0px;
          // background: #1ba27a;
        }
        .lowerSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
        }
        .contentSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 52px;
        }
        .BottomValue {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;

          text-align: center;

          color: var(--font-color);
        }
        .retunsValue {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;

          color: #4caf50;
        }
        .BottomDetail {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;
          margin-bottom: 7px;

          color: var(--font-color);
        }
        .fundDes {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 300;
          font-size: 11px;
          margin-top: 18px;
          line-height: 20px;
          color: var(--font-color);
        }
        .fundsName {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: var(--font-color);
        }
        .fundsvalue {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;

          text-align: right;

          color: var(--font-color);
        }
      }
    }
  }
}

.Tablecardfx {
  display: flex;
  align-items: self-start;
  gap: 3.4%;
  margin-bottom: 2.5rem;
  .innerTablecard {
    width: 294px;
    height: 152px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 20px;
    align-items: center;
    &:hover {
      transform: translateY(-5px);
      // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    img {
      height: 33.23px;
    }
    .fundsNamesub {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-top: 1.2rem;

      color: var(--font-color);
    }
  }
}

.TableSectionfx {
  .headerFunds {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;

    padding-left: 1rem;
  }

  .headerFundsEA {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;

    padding-left: 1rem;
  }
  .fund-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 50px;
    /* or 455% */

    color: var(--font-color);
    &:nth-child(5) {
      text-align: center;
    }
  }

  .fundBody {
    .img-table-frist {
      display: flex;
      align-items: center;
      margin-top: 2px;
    }
    .sectionLeftBody {
      display: flex;
      align-items: center;
    }
    .InnerFundsBody {
      padding-top: 3rem;
      padding-bottom: 3rem;
      border-bottom: 0.5px solid #e7e7e7;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }

    .InnerFundsBodyEa {
      padding-top: 3rem;
      padding-bottom: 3rem;
      border-bottom: 0.5px solid #e7e7e7;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .InnerFundsBody {
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
    }

    .InnerFundsBodyEa {
      display: grid;
      grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
      padding-left: 1rem;
    }
    .text-funds {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      display: flex;
      align-items: center;
      line-height: 18px;

      color: var(--font-color);
    }
    .labelcover {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .buylabel {
      width: 109px;
      height: 31px;
      left: 1355px;
      top: 443px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: var(--font-color);

      background: #9fd6df;
      border-radius: 25px;
      background: #ffffff;
      opacity: 0.9;
      border: 0.5px solid #e5e5e5;
      border-radius: 25px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .logo-fund-inner {
      height: 14px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .logo-fund {
      height: 37px;
      margin-right: 10px;
    }
    .text-funds-inner {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;

      color: var(--font-color);
    }
  }
}

.ErrorMessageDash {
  height: calc(100vh - 35.4vh);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    color: var(--font-color);
    width: 762px;
    margin-bottom: 4rem;
  }
  .have {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--font-color);
    justify-content: center;
    background: #fff;
    opacity: 0.9;
    border-radius: 25px;
    width: 293px;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 54px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .sign {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    justify-content: center;
    background: linear-gradient(90deg, #81cbd7 0%, #3d4394 100%);
    opacity: 0.9;
    border-radius: 25px;
    width: 293px;
    height: 54px;
    &:hover {
      transform: scale(1.1);
    }
  }
}
