@import '../../static/scss/colours';

.topNavbar {
  width: 100%;
  height: 8%;
  border-bottom: 0.5px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;

  & > .vaultsIcon {
    width: 10%;

    & > img {
      width: 100%;
      max-height: 100%;
    }
  }

  & > .searchVaults {
    width: 40%;
    height: 100%;
    background: rgba(255, 255, 255, 0.37);
    border: 1.5px solid #ededed;
    border-radius: 35px;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: rgb(24, 37, 66, 0.25);

    & > div {
      //   padding: 0.5rem 2rem;
    }

    & > .dropDown {
      width: 30%;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;

      & > div {
        white-space: nowrap;
        & > svg {
          font-size: 2.5rem;
        }
      }
    }

    & > .searchDiv {
      width: 70%;
      height: 100%;

      & > input {
        width: 100%;
        border: none;
        outline: none;
        height: 100%;
        background: transparent;
        padding: 0 1.5rem;

        &::placeholder {
          font-weight: 700;
          color: rgb(24, 37, 66, 0.25);
        }
      }
    }

    &:hover {
      border: 1.5px solid black;
    }
  }

  & > .btnDiv {
    // visibility: hidden;
    padding: 0.5rem 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #4caf50;
    opacity: 0.9;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 700;
    color: white;
    font-size: 1rem;
    // margin-left: 20vw;
    white-space: nowrap;
  }

  & > .balance {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-right: 0.5px solid #ededed;

    & > div {
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;
      font-weight: 600;
      color: rgb(95, 97, 99, 0.4);

      & > .amount {
        // opacity: 1;
        font-size: 1.2rem;
        color: var(--font-color);
      }
    }
  }

  & > .balanceActions {
    width: 24%;
    display: flex;
    justify-content: space-between;
    // align-items: ;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      border-radius: 15px;
      font-size: 1rem;
      font-weight: 600;
      color: white;
      padding: 0.5rem 1rem;
      white-space: nowrap;
      cursor: pointer;
    }

    & > .addMore {
      background: #4dc089;
    }

    & > .withdraw {
      background: #cd1e25;
    }
  }
}

.switchNavbar {
  width: 100%;
  height: 4%;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  gap: 3rem;
  // padding: 0rem 3rem;
  overflow-y: scroll;
  padding: 5px 3rem;
}

.switchItems {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.35rem;
  cursor: pointer;
  position: relative;
  transition: all ease-in 0.4s;
  height: 100%;
  //   border: 1px solid red;
  padding: 10px 1rem;

  & > img {
    width: 1rem;
    height: 1rem;
  }
  & > div {
    font-size: 1rem;
  }

  & > .hoverDiv {
    width: 15%;
    height: 0.25rem;
    border-radius: 10px 10px 0 0;
    background: #4caf50;
    position: absolute;
    bottom: 0;
    display: none;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // &:hover {
  //   & > .hoverDiv {
  //     display: block;
  //   }
  // }
}

.switchItemsSelected {
  background: rgba(237, 237, 237, 0.37);
  border-radius: 25px;
  font-weight: 600;
}

.vaultDetailedSection {
  width: 100%;
  height: calc(100% - 12%);
  overflow-y: scroll;
  overflow-x: hidden;
  // border: 1px solid red;
  color: var(--font-color);
  // padding: 3rem;
  padding-bottom: 0;
  background: #f5f5f5;

  & > .holdingValue {
    min-height: 10%;
    & > :nth-child(1) {
      font-weight: 700;
      font-size: 2.5rem;
    }
    & > :nth-child(2) {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }

  & > .contentDiv {
    padding-top: 2.4rem;
    // border: 1px solid red;
    width: 100%;
    height: 100%;

    & > .tokenSwitch {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 3rem;
      border-bottom: 1px solid #e7e7e7;
      border-top: 1px solid #e7e7e7;
      height: 3rem;

      & > div {
        width: 13.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 1rem 0;
        cursor: pointer;

        transition: all ease-in 0.4s;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;

        color: var(--font-color);

        &:hover {
          font-size: 1.025rem;
        }
      }

      & > :nth-child(1) {
        // border-bottom: 2.5px solid var(--font-color);
        font-weight: 600;
      }
    }

    & > .cardsContainer {
      width: 100%;
      height: auto;
      // height: inherit;
      // border: 2px solid blue;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      padding: 3rem 3rem;
      // overflow: scroll;
    }
  }
}

.singleCard {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  border: 0.5px solid #e5e5e5;
  // height: 10rem;
  padding: 1.2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: max-content;
  min-height: 20rem;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  & > .topDiv {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;

    & > .itemDiv {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > img {
        height: 60%;
        max-height: 60%;
        object-fit: contain;
        max-width: 60%;
      }

      & > div {
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 3vh;
      }
    }

    & > .dotsIcon {
      & > svg {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }

  & > .bottomDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > div {
      font-size: 1.2rem;
      font-weight: 600;
    }

    & > :nth-child(1) {
      color: var(--font-color);
    }

    & > :nth-child(2) {
      color: #4dc089;
    }
  }
}
